import React from "react";
import { StaticQuery, graphql } from "gatsby";

import DynamicNav, { toTree } from "./dynamicNav";
import SidebarLink from "./sidebarLink";

const navQuery = graphql`
  query NavQuery {
    allSitePage(filter: { context: { draft: { ne: false } } }) {
      nodes {
        path
        context {
          draft
          title
          sidebar_order
          sidebar_title
        }
      }
    }
  }
`;

type NavNode = {
  path: string;
  context: {
    draft: boolean;
    title: string;
    sidebar_order: number;
    sidebar_title?: string;
  };
};

type ChildProps = {
  data: {
    allSitePage: {
      nodes: NavNode[];
    };
  };
};

export const Sidebar = ({ data }: ChildProps): JSX.Element => {
  const tree = toTree(data.allSitePage.nodes.filter(n => !!n.context));
  return (
    <ul className="list-unstyled" data-sidebar-tree>
      <DynamicNav
        root="guides"
        title="Hướng dẫn sử dụng"
        tree={tree}
        exclude={[
          "/guides/ocr-keys/",
          "/guides/integrations/",
        ]}
      />
      <DynamicNav
        root="guides/ocr-keys"
        title="OCR Keys"
        tree={tree}
      />
      <DynamicNav
        root="guides/integrations"
        title="Tích hợp"
        tree={tree}
      />
    </ul>
  );
};

export default () => {
  return (
    <StaticQuery query={navQuery} render={data => <Sidebar data={data} />} />
  );
};
