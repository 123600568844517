import React from "react";

import SmartLink from "./smartLink";

export default (): JSX.Element => {
  return (
    <div className="navbar navbar-expand-md navbar-light bg-white global-header">
      <SmartLink
        to="/"
        title="DocBase AI error monitoring"
        className="navbar-brand pb-0"
      >
        <img src="/DocBase_logo.svg" alt="image" />
      </SmartLink>

      <button
        className="d-md-none btn btn-outline-dark"
        type="button"
        onClick={() => {
          const el = document.getElementById("sidebar");
          if (el.style.display === "block") {
            el.style.display = "none";
          } else {
            el.style.display = "block";
          }
        }}
        aria-controls="sidebar"
        aria-expanded="false"
        aria-label="Toggle table of contents"
      >
        Table of Contents
      </button>
    </div>
  );
};
